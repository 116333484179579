import React, { Component } from 'react';
import FooterComponent from './../../Generic/Footer/FooterComponent';
import HeaderComponent from './../../Generic/Header/HeaderComponent';
import OrderDetails from './OrderDetails/OrderDetails';
import BreadCrumbs from './../../Generic/BreadCrumbs/BreadCrumbs';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import EmptyCart from './EmptyCart';

import * as service from './../../../Service/service';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

class CartComponent extends Component {

    state = {
        cart: {
            cartProductList: []
        },
        slugs: [],
        showCart: false,
        billing: {},
        razorpayInfo: {},
        orderData: {},
        paymentConfirmation: false,
        razorPayKey: '',
        isupdating: false,
        isMed: false,
        enquiryData: {},
        isPremium:false,
        isValidOrder:false,
    }

    getRazorPayKeys = () => {

        service.razorPayKeys().then((response) => {
            this.setState({
                razorPayKey: response.data
            });

        }, (error) => {
            console.log(error);
        });
    }

    checkCart = () => {
        this.setState({ showCart: false });
    
        service.checkCart().then((response) => {
            if (response.data?.cartProductList?.length) {
                let slugs = [];
    
                response.data.cartProductList.forEach((product) => {
                    if (product.isMedical && !this.state.isMed) {
                        this.setState({ isMed: true });
                    }
                    if (product.productSlug) {
                        slugs.push(product.productSlug);
                    }
                });
    
                
                this.setState({
                    cart: response.data,
                    slugs: slugs,
                });
    
                setTimeout(() => {
                    this.cartProductInfo();
                }, 100);
            }
        }).catch((error) => {
            console.log("Error in checkCart:", error);
        });
    };
    
    

    cartProductInfo = () => {
        
        let cart = JSON.parse(JSON.stringify(this.state.cart));
        let premiumCount = 0;
        let totalProducts = cart.cartProductList.length;


        service.cartProductInfo(this.state.slugs).then((response) => {

            response.data.map((prod) => {

                cart.cartProductList.map((cartProduct) => {
                    if (cartProduct.productSlug === prod.slug) {
                        cartProduct.productInfo = prod;
                    }
                });
                if(prod.category.slug==='premium'){
                    premiumCount++;
                    
                }

            });
                const isOnlyPremium = premiumCount === totalProducts;
                const isOnlyNonPremium = premiumCount === 0;
                const isValidOrder = isOnlyPremium || isOnlyNonPremium; 
                console.log(isOnlyNonPremium, isOnlyPremium, isValidOrder)

            this.setState({
                ...this.state,
                cart: cart,
                showCart: true,
                isValidOrder: isValidOrder,
                isPremium: isOnlyPremium, 
            });

            setTimeout(() => {
                this.calculateBilling();
            }, 100);

        }, (error) => {
            console.log(error);
        });
    }

    calculateBilling = () => {

        let billing = {
            totalRent: 0,
            totalDeposit: 0,
            deliveryFee: 0,
            couponDiscount: 0,
            payable: 0
        };
        if(this.state.isPremium){
            this.state.cart.cartProductList.map((products) => {
                billing.totalRent += products.monthlyRent;
                billing.totalDeposit += products.monthlyRent * 11;
                billing.payable += products.monthlyRent * 12;
            });
        }
        else{
            this.state.cart.cartProductList.map((products) => {
                billing.totalRent += products.monthlyRent;
                billing.totalDeposit += products.monthlyRent * 2;
                billing.payable += products.monthlyRent * 3;
            });
        }
        

        if (this.props.coupon.applied && billing.totalRent > this.props.coupon.couponDetails.minAmount) {

            if (this.props.coupon.couponDetails.promotionType == "%") {
                billing.couponDiscount = (billing.totalRent * (parseFloat(this.props.coupon.couponDetails.promotionValue) / 100)).toFixed(2);
                if (billing.couponDiscount > this.props.coupon.couponDetails.maxDiscount) {
                    billing.couponDiscount = this.props.coupon.couponDetails.maxDiscount;
                }
                billing.totalRent = billing.totalRent - billing.couponDiscount;
                billing.totalDeposit = 2 * billing.totalRent;
            } else if (this.props.coupon.couponDetails.promotionType == "-") {
                billing.couponDiscount = this.props.coupon.couponDetails.promotionValue;
                billing.totalRent = billing.totalRent - billing.couponDiscount;
                billing.totalDeposit = 2 * billing.totalRent;
            }

            billing.payable = (billing.totalRent + billing.totalDeposit).toFixed(2);
            billing.couponDiscount = billing.couponDiscount;
        }


        this.setState({
            ...this.state,
            billing: billing
        });

    }

    placeOrder = (addressId) => {
        var rent = Math.round(this.state.billing.totalRent);
    
        if (localStorage.city.indexOf("gur") !== -1) {
            if (rent < 400 && !this.state.isPremium) {
                alert("Minimum monthly rental amount to place order is Rs.400");
                return;
            }
        } else if (rent < 1000 && !this.state.isPremium) {
            alert("Minimum monthly rental amount to place order is Rs.1000");
            return;
        }
    
        // Prevent order placement if all products are not premium
        if (!this.state.isValidOrder) {
            alert("Only premium products can be ordered. Please remove non-premium products from the cart.");
            return;
        }
    
        if (addressId !== 0) {
            this.setState({
                ...this.state,
                isupdating: true,
            });
    
            var orderData = {
                addressId: addressId,
                cart: this.state.cart.cartProductList,
                couponId: this.props.coupon.couponCode,
                couponDiscount: this.state.billing.couponDiscount,
                isMedical: this.state.isMed,
                isPremium: this.state.isPremium,
            };
    
            service.placeOrder(orderData).then((response) => {
                this.setState({
                    ...this.state,
                    orderData: response.data,
                    isupdating: false
                });
    
                if (response.data !== null && this.state.isMed !== true) {
                    this.generateRazorPayOrderId(response.data.orderNumber);
                }
                if (response.data !== null && this.state.isMed === true) {
                    this.props.updateCurrentOrder(this.state.orderData);
                    setTimeout(() => {
                        this.props.history.push('/order/success');
                    }, 100);
                }
            }, (error) => {
                console.log(error);
            });
        } else {
            alert("This area is not applicable for delivery");
        }
    };
    

    generateRazorPayOrderId = (orderNumber) => {
        service.getRazorpayOrderId(orderNumber).then((response) => {
            this.setState({
                ...this.state,
                razorpayInfo: response.data
            });

            setTimeout(() => {
                this.initiatePayment();
            }, 100);
        }, (error) => {
            console.log(error);
        });
    }

    initiatePayment = () => {
        var options = {
            "key": this.state.razorPayKey,
            "amount": this.state.razorpayInfo.amount_due,
            "currency": this.state.razorpayInfo.currency,
            "name": "Voorent Pvt. Ltd",
            "description": "",
            "image": "https://voorent.com/voorent-storage/application/voorent.png",
            "order_id": this.state.razorpayInfo.id,
            "handler": (response) => {
                this.updateRazorpayPayments(response);
            },
            "modal": {
                "ondismiss": () => {
                    this.props.history.push('/order/failure');
                }
            },
            "prefill": {
                "name": `${this.props.userInfo.firstName} ${this.props.userInfo.lastName}`,
                "email": `${this.props.userInfo.email}`,
                "contact": `${this.props.userInfo.contact}`
            },
            "theme": {
                "color": "#01497f"
            }
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    updateRazorpayPayments = (paymentUpdate) => {

        this.setState({
            ...this.state,
            isupdating: true
        });

        let obj = {
            razorpayPaymentId: paymentUpdate.razorpay_payment_id,
            razorpayOrderId: paymentUpdate.razorpay_order_id,
            razorpaySignature: paymentUpdate.razorpay_signature,
            orderNumber: this.state.orderData.orderNumber
        };

        service.updatePayment(obj).then((response) => {

            this.setState({
                ...this.state,
                paymentConfirmation: response.data,
                isupdating: false
            });

            if (response.data) {
                //payment success
                this.props.updateCurrentOrder(this.state.orderData);
                //this.createSubscription(this.state.orderData.orderNumber);
                setTimeout(() => {
                    this.props.history.push('/order/success');
                }, 100);

            } else {
                //payment failure
            }

        }, (error) => {
            console.log(error);
        });
    }

    createSubscription = (orderNumber) => {

        service.getRazorpaySubscriptionId(orderNumber).then((response) => {
            this.setState({
                ...this.state
            });

        }, (error) => {
            console.log(error);
        });


    }

    deleteProductFromCart = (productSlug) => {

        let productList = this.state.cart.cartProductList;
        productList.map((elem, index) => {
            if (elem.productSlug == productSlug) {
                productList.splice(index, 1);
            }
        });

        this.setState({
            ...this.state,
            cart: {
                ...this.state.cart,
                cartProductList: productList
            }
        });

        service.deleteCartItem(productSlug).then((response) => {
            if (response.status == 200) {
                this.checkCart();
            }
        }, (error) => {
            console.log(error);
        })
    }

    manageQuantity = (operator, slug) => {

        let product = {
            quantity: 1,
            productSlug: slug
        };

        let tmpState = this.state;
        tmpState.cart.cartProductList.map((item) => {
            if (item.productSlug === slug && item.quantity > 1 || operator == 'add') {
                item.quantity = operator == 'add' ? item.quantity += 1 : item.quantity -= 1;
                product.quantity = item.quantity;
            }
        });

        this.setState(tmpState);

        service.manageQuantity(product).then((response) => {
            if (response.status == 200) {
                this.checkCart();
            }
        }, (error) => {
            console.log(error);
        });
    }

    checkIfEmpty = () => {
        if (this.state.cart.cartProductList.length == 0) {
            return (<EmptyCart />);
        } else {
            return (
                <React.Fragment>
                    <div className="row ">
                        <div className="col-12 col-md-8" style={{ position: 'relative', borderRadius: '4px' }}>
                            <OrderDetails
                                isMedical={this.state.isMed}
                                isPremium={this.state.isPremium}
                                calculateBilling={this.calculateBilling}
                                manageQuantity={this.manageQuantity}
                                deleteProductFromCart={this.deleteProductFromCart}
                                cart={this.state} />
                        </div>
                        <div className="col-12 col-md-4" style={{ position: 'relative', borderRadius: '4px' }}>
                            <CustomerDetails placeOrder={this.placeOrder} isMedical={this.state.isMed} updating={this.state.isupdating} />

                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }


    componentDidMount() {
        this.checkCart();
        this.getRazorPayKeys();
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: './' }, { name: 'Cart', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <link rel="canonical" href="https://voorent.com/cart" />
                </Helmet>
                <HeaderComponent />
                <BreadCrumbs />
                <main role="main" className="container mt-5">
                    <div className="row">

                        {
                            this.checkIfEmpty()
                        }

                    </div>
                </main>
                <FooterComponent />
            </React.Fragment>
        );
    }
}
const mapStateToProps = (currentState) => {
    return {
        order: currentState.order,
        userInfo: currentState.userState.userInfo,
        coupon: currentState.coupon
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateCurrentOrder: (data) => {
            dispatch({ type: 'update.order.current', data: data });
        },
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}


export default connect(mapStateToProps, mapActionsToProps)(CartComponent);