import React, { Component } from "react";
import FooterComponent from "./../../Generic/Footer/FooterComponent";
import HeaderComponent from "./../../Generic/Header/HeaderComponent";
import BannerSliderComponent from "./BannerSlider/BannerSliderComponent";
import WhatWeDoComponent from "./WhatWeDo/WhatWeDoComponent";
import H2Title from "./../../Generic/Element/Heading";
import SingleProduct from "./../../Generic/Element/SingleProduct"
// import Package from "./../../Generic/Element/Package";
// import Testimonial from "./Testimonial/Testimonial";
import * as service from "./../../../Service/service";
import * as endpoint from "./../../../Service/endpoints";

import B2BReviews from "./B2BCustomers/B2BReviews";
import RentVsOwnComponent from "./RentVsOwn/RentVsOwnComponent";
import Blogs from "./Blogs/Blogs";
import TestinomialSlider from "./TestinomialNew/TestinomialSlider";
// import BuyBanner from "./BuyingBanner/BuyBanner";
import ResponseForm from "./GoogleForm/ResponseForm";
import ServiceAreaSelect from "./ServiceArea/ServiceAreaSelect";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

class HomeComponent extends Component {
  state = {
    values: [
      {
        id: 1,
        quality: "Quality Rentals at Best Prices",
        icon: "ribbon",
      },
      {
        id: 2,
        quality: "Easy Customization & Returns",
        icon: "extension",
      },
      {
        id: 3,
        quality: "Free Delivery & Maintenance",
        icon: "construct",
      },
      {
        id: 4,
        quality: "Flexible Rental Plans",
        icon: "settings",
      },
      {
        id: 5,
        quality: "24/7 Customer Support",
        icon: "chatbubbles",
      },
    ],
    popular: [],
    trending: [],
    packages: [],
    openResponseForm:false,
    testinomials: [
      {
        description:
          "Great experience.. Great Service. The quality of furniture is very good and the rental rates are very affordable. Strongly Recommended.",
        name: "Rajat",
        location: "Gurgoan",
        imageUrl:
          "https://voorent.com/voorent-storage/images/rajat.png",
      },
      {
        description:
          "Just what I wanted. Verification is easy and hassle free. Delivery on time and quality of furniture is awesome. Keep it up like that",
        name: " Shraddha",
        location: "Delhi",
        imageUrl:
          "https://voorent.com/voorent-storage/images/shraddha.png",
      },
      {
        description:
          "Their service is excellent and so is the quality of the furniture that Voorent provides. The delivery boys seated the furniture as desired.",
        name: "Tanuj",
        location: "Noida",
        imageUrl:
          "https://voorent.com/voorent-storage/images/tanuj.png",
      },
    ],
    b2b: [
      {
        description: `Voorent has been our associate for more than 3 years and have always delivered high quality
            furniture backed by promot services. Highly approachable founders who are open to feedback and exhibit a high level of action orientation in terms of product design and delivery. Fast, reliable 
            and cost effective.`,
        name: "Amber Sajid",
        corporate: "Co-Founder, COHO",
        corporateLogo:
          "https://voorent.com/voorent-storage/images/logo_coho.png",
        class: { width: "48px" },
      },
      {
        description: `I have been a part of this student housing indeustry for 4 years now and have worked/initaited discussion regarding furniture on rent wiht a lot of vendors but my experience with
             the voorent team has been best till date. They were the first company to delivery exactly what was promoised ,
              and exactly on time. The quality of the furniture was also worth the price.`,
        name: "Sunny Garg",
        corporate: "Founder/CEO, YourShell",
        corporateLogo:
          "https://voorent.com/voorent-storage/images/your-shell.png",
        class: { width: "150px" },
      },
      {
        description: `We have recently started our partnership with Voorent, and the experience has been good. They were flexible and listened to our suggestion.
             Look forward to working with them in the future. `,
        name: "Shubha Lal",
        corporate: "Co-Founder, Yourspace",
        corporateLogo:
          "https://voorent.com/voorent-storage/images/your-space.png",
        class: {
          width: "150px",
          background: "#3a3633",
          borderRadius: "4px",
          padding: "4px",
        },
      },
      {
        description: `Voorent has been our partner for more than 6 months and in this short span have become a very reliable
             partner who with their specialization in providing customized furniture are giving a unique look to our properties.
              They are very efficient in their services and always provide high quality furniture.`,
        name: "Mohammed Sirajuddin",
        corporate: "Co-Founder, Creator Gurukul",
        corporateLogo:
          "https://voorent.com/voorent-storage/images/gurukul.png",
        class: {
          width: "48px",
          background: "#3a3633",
          borderRadius: "4px",
          padding: "4px",
        },
      },
    ],
    posts: [
      {
        id: 1,
        title: 'My First Blog Post',
        author: 'John Smith',
        date: 'April 18, 2023',
        content: '<p>This is my first blog post.</p><p>It is very exciting.</p>',
        excerptLength: 100,
      },
      {
        id: 2,
        title: 'My Second Blog Post',
        author: 'John Smith'
      }
    ],
    voorentInfo: [
      {
        title: "No Maintenance",
        description:
          "Whether you want to rent furniture or appliance, you won’t have to pay extra for maintenance. Most of the things in your home may come with the responsibility of regular upkeep. But, when you rent it from Voorent, we keep your things up and working.",
        imageUrl: "./../svg/settings.svg",
        align: "right",
      },
      {
        title: "Zero Loan Responsibilities",
        description:
          "Buying new furniture may come with a loan account. But, renting can solve your decor worries and still not put you under the stress of clearing loan installments. Further, our team at Voorent will take care of the upkeep and maintenance of the things you rent.",
        imageUrl: "./../svg/loan.svg",
        align: "left",
      },
      {
        title: "No Budget Limitations",
        description:
          "When purchasing household products, furniture, and other necessary things, you have to face budget limitations. However, wouldn’t it be amazing to keep the budget limitations aside and just rent that favorite sofa of yours? Rent products from Voorent.",
        imageUrl: "./../svg/budget.svg",
        align: "right",
      },
      {
        title: "Impeccable Support",
        description:
          "Just in case, you face any issues after renting the products, you can reach out to us anytime. Our experienced customer executives will ensure the earliest resolution of your issues. You simply don’t have to worry about anything, contact Us whenever you need guidance.",
        imageUrl: "./../svg/support.svg",
        align: "left",
      },
    ],
    isContactModalOpen: false,

  };

  componentDidMount() {
    
    ReactGA.initialize('UA-125546749-1'); 
    ReactGA.pageview(window.location.pathname);

    service.getTrendingProducts().then(
      (response) => {
        this.setState({
          ...this.state,
          trending: response.data,
        });
      },
      (error) => {
        console.log(error);
      }
    );

    service.getPopularProducts().then(
      (response) => {
        this.setState({
          ...this.state,
          popular: response.data,
        });
      },
      (error) => {
        console.log(error);
      }
    );

    service.getPackages().then(
      (response) => {
        this.setState({
          ...this.state,
          packages: response.data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  responseForm() {
    this.setState({...this.state,
      openResponseForm : true});
  }

  openContactModal = () => {
    this.setState({...this.state,
      isContactModalOpen : true});
  };

  closeContactModal = () => {
    this.setState({...this.state,
      isContactModalOpen : false});
  };

  render() {
    // const { isContactModalOpen } = this.state.isContactModalOpen;
    setTimeout(()=>this.responseForm(), 5000)
    return (
      <React.Fragment>
        <Helmet>
            <meta name="description" content="Living in a rented flat and want home appliances on rent? We are here with the solution, Rent home appliances in delhi on affordable rates from voorent." />
            <meta name="keywords" content="Rent appliances in delhi" />
            <meta name="title" content="Rent home appliances in delhi at affordable rates." />
            <link rel="canonical" href="https://voorent.com/" />
        </Helmet>
        {
          (window.location.href.indexOf("medium") > -1) &&(
            <div>

              {
                this.state.openResponseForm && (
                  <ResponseForm/>
                )
              }
              {
                !this.props.surveyModal.show && (
                <ServiceAreaSelect/>)
              }
          </div>)
        }
        <HeaderComponent />

        
        <BannerSliderComponent />

        <div className="d-none d-sm-block">
          <div
            className="row text-center"
            style={{
              backgroundImage: "linear-gradient(270deg,rgba(211, 205, 205, 0.67),rgba(211, 205, 205, 0.67))",
              minHeight: "105px",
            }}
          >
            {this.state.values.map((value, index) => (
              <WhatWeDoComponent
                key={index}
                quality={value.quality}
                icon={value.icon}
              />
            ))}
          </div>
        </div>

        <main role="main" className="container m-auto">
          <div className="d-block d-sm-none">
            <div className="row mt-4 justify-content-center ">
              <a href={endpoint.getRoute("appliance")} className="ml-2 mr-2">
                <div className="text-center">
                  <img width="48px" src="./../svg/appliance.svg" alt="" />
                  <span className="d-block font-weight-bold">
                    View Appliances
                  </span>
                </div>
              </a>
              <a href={endpoint.getRoute("furniture")} className="ml-2 mr-2">
                <div className="text-center">
                  <img width="48px" src="./../svg/furniture.svg" alt="" />
                  <span className="d-block font-weight-bold">
                    View Furniture
                  </span>
                </div>
              </a>
              <a href={"https://parvhomes.com/"} className="ml-2 mr-2">
                <div className="text-center">
                  <img width="48px" src="./../svg/buy-icon.svg" alt="" />
                  <span className="d-block font-weight-bold">
                    Buy Now
                  </span>
                </div>
              </a>
            </div>
          </div>

          {/* <div className="row mt-4">
            <div className="col-12">
              <H2Title value={"Super Saver Combo Pack"} />
              <div className="row">
                {this.state.popular.length != 0 &&
                  this.state.popular.map(
                    (prod, index) =>
                      index < 3 && (
                        <SuperSaver
                          key={index}
                          product={prod}
                          minTenure={"6 Months"}
                          styleValue={{ padding: "" }}
                        />
                      )
                  )}
              </div>
            </div>
          </div> */}

          {/* <div className="row mt-4">
            <div className="col-12">
              <H2Title value={"Super Saver Packages"} />
              <div className="row">
                {this.state.packages.length != 0 &&
                  this.state.packages.map(
                    (prod, index) =>
                      index < 3 && (
                        <Package
                          key={index}
                          product={prod}
                          minTenure={"6 Months"}
                          styleValue={{ padding: "" }}
                        />
                      )
                  )}
              </div>
            </div>
          </div> */}

          <div className="row mt-4">
            <div className="col-12">
              <H2Title value={"Most Viewed Items"} />
              <div className="row">
                {this.state.popular.length !== 0 &&
                  this.state.popular.map(
                    (prod, index) =>
                      index < 3 && (
                        <SingleProduct
                          key={index}
                          product={prod}
                          minTenure={"6 Months"}
                          styleValue={{ padding: "" }}
                        />
                      )
                  )}
              </div>
            </div>
          </div>

          {/*<div className="row mt-4">
            <div className="col-12">
              <div className="row">
                <BuyBanner />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
            <H2Title value={"We have lot more products from our partners."} />
              <div className="row mt-4 mb-4" style={{justifyContent:"center"}}>

              
          <a href="/indianrentals" className="ml-2 mr-2" >
                
              <img style={{maxWidth:"1100px"}} src="https://voorent.com/voorent-storage/images/bannerwithupdate/Indian+Renters+poster.jpg" alt="" />
                
          </a>
          </div>
          </div>
          </div>*/}
          
          <div className="row mt-4">
            <div className="col-12">
              <H2Title value={"Trending Items"} />
              <div className="row">
                {this.state.trending.length !== 0 &&
                  this.state.trending.map(
                    (prod, index) =>
                      index < 3 && (
                        <SingleProduct
                          key={index}
                          product={prod}
                          minTenure={"6 Months"}
                          styleValue={{ padding: "" }}
                        />
                      )
                  )}
              </div>
            </div>
          </div>

          <div className="d-none d-sm-block">
            <div className="row mt-4">
              <div className="col-12">
              <img
                style={{ minHeight: "220px" }}
                className="d-block w-100 lazyload "
                src="https://voorent.com/voorent-storage/images/banner_1.png"
                alt="Kitchen"></img>
              </div>
            </div>
          </div>

          

          <div className="d-none d-sm-block">
            <div className="row mt-4">
              <div className="col-12">
                <H2Title value={"Renting Vs Owning"} />
                <div className="row">
                  <RentVsOwnComponent />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row mt-4">
                        <div className="col-12">
                            <H2Title value={'What people are saying about us'} />
                            <div className="row m-auto">
                                {
                                    this.state.testinomials.map((testimonial, index) => (
                                        <Testimonial key={index} testimonial={testimonial} />
                                    ))
                                }
                            </div>
                        </div>
                    </div> */}
        </main>

        <div className="row mt-4">
          <div className="col-12">
            <div className="row m-auto">{<TestinomialSlider />}</div>
          </div>
        </div>

        <main role="main" className="container m-auto">
          <div className="row mt-4">
            <div className="col-12">
              <H2Title value={"What our corporate customers say"} />
              <div className="row m-auto">
                {this.state.b2b.map((element, index) => (
                  <B2BReviews key={index} item={element} />
                ))}
              </div>
            </div>
          </div>
        </main>

        <main role="main" className="container m-auto">
          <div className="row mt-4">
            <div className="col-12">
            <H2Title value={"From the Blog"} />
                <div className="row">
                  <Blogs />
              </div>
            </div>
          </div>
        </main>

        <FooterComponent />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (currentState) => {
  return {
      surveyModal: currentState.surveyModal
  };
};


const mapActionsToProps = (dispatch) => {
  return {
      
  };
};

export default connect(mapStateToProps, mapActionsToProps)(HomeComponent);
