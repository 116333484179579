import React, { Component } from 'react';
import './../../../styles/heading.css'

export default class H1Title extends Component {
    render() {
        return (
        <h1 className="mb-3 ml-2 voo-underline heading" style={{fontWeight: 300,
            display: 'inline-block',
            paddingBottom: '8px',
            position: 'relative',
            fontSize: '30px',}}>{this.props.value}</h1>
        );
    }
};