import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './index.css';
import * as serviceWorker from './serviceWorker';

import reducer from './Store/reducer';
import HomeComponent from './Components/User/Homepage/HomeComponent';
import CategoryComponent from './Components/User/Category/CategoryComponent';
import SubCategoryComponent from './Components/User/SubCategory/SubCategoryComponent';
import ProductComponent from './Components/User/Product/ProductComponent';
import AccountWrapper from './Components/Account/AccountWrapper';
import SettingsComponent from './Components/User/Settings/SettingsComponent';
import CartComponent from './Components/User/Cart/CartComponent';
import OrderConfirmationComponent from './Components/User/Order/OrderConfirmationComponent';
import VRComponent from './Components/Standalone/VRComponent';
import VRKitchen from './Components/Standalone/VRKitchen'
import AboutUsComponent from './Components/User/InfoPages/AboutUS/AboutUsComponent';
import FAQComponent from './Components/User/InfoPages/FAQ/FAQComponent';
import PrivacyPolicyComponenet from './Components/User/InfoPages/PrivacyPolicy/PrivacyPolicyComponenet';
import TermsAndConditionsComponent from './Components/User/InfoPages/TermsAndConditions/TermsAndConditionsComponent';

import Blog1Component from './Components/User/InfoPages/Blogs/Blog1Component';
import Blog2Component from './Components/User/InfoPages/Blogs/Blog2Component';
import Blog3Component from './Components/User/InfoPages/Blogs/Blog3Component';
import Blog4Component from './Components/User/InfoPages/Blogs/Blog4Component';
import Blog5Component from './Components/User/InfoPages/Blogs/Blog5Component';
import Blog6Component from './Components/User/InfoPages/Blogs/Blog6Component';
import Blog7Component from './Components/User/InfoPages/Blogs/Blog7Component';
import Blog8Component from './Components/User/InfoPages/Blogs/Blog8Component';
import Blog9Component from './Components/User/InfoPages/Blogs/Blog9Component';

import ResetPassword from './Components/User/Login/Reset/ResetPassword';

import InvoiceComponent from './Components/Standalone/InvoiceComponent';
import OrderSummaryComponent from './Components/Standalone/OrderSummaryComponent';

import axios from 'axios';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import SearchComponent from './Components/User/Search/SearchComponent';
import LoginPageComponent from './Components/User/GeneralLogin/LoginPage';
import NewProductsHomepage from './../src/Components/Generic/NewProductHomepage/NewProductsHomepage'
import AddNewAddress from './Components/Standalone/AddNewAddress';
import CustomizationComponent from './Components/User/Customization/CustomizationComponent';
import Blog10Component from './Components/User/InfoPages/Blogs/Blog10Component';
import Blog11Component from './Components/User/InfoPages/Blogs/Blog11Component';
import Blog12Component from './Components/User/InfoPages/Blogs/Blog12Component';
import Blog13Component from './Components/User/InfoPages/Blogs/Blog13Component';
import Blog14Component from './Components/User/InfoPages/Blogs/Blog14Component';
import Blog15Component from './Components/User/InfoPages/Blogs/Blog15Component';
const store = createStore(reducer);

let keycloak = {};

ReactGA.initialize('UA-125546749-1'); 


window.addEventListener('login', function (e) {

    keycloak = JSON.parse(localStorage.keycloak);
    axios.defaults.headers.common['Authorization'] = keycloak.token_type + " " + keycloak.access_token;
    
}, false);

if (typeof localStorage.keycloak != "undefined") {
    keycloak = JSON.parse(localStorage.keycloak);
    axios.defaults.headers.common['Authorization'] = keycloak.token_type + " " + keycloak.access_token;
}

const routing = (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route exact path="/indianrentals" component={NewProductsHomepage} />
                <Route exact path="/user/add/address" component={AddNewAddress} />
                <Route exact path="/vrBedroom" component={VRComponent} />
                <Route exact path="/vrKitchen" component={VRKitchen} />
                <Route exact path="/account" component={AccountWrapper} />
                <Route exact path="/account/invoices/:invoiceNumber" component={InvoiceComponent} />
                <Route exact path="/account/order/:orderNumber" component={OrderSummaryComponent} />
                <Route exact path="/cart" component={CartComponent} />
                <Route exact path="/settings" component={SettingsComponent} />
                <Route exact path="/settings" component={SettingsComponent} />
                <Route exact path="/order/:status" component={OrderConfirmationComponent} />
                <Route exact path="/about-us" component={AboutUsComponent} />
                <Route exact path="/faq" component={FAQComponent} />
                <Route exact path="/customization" component={CustomizationComponent} />
                <Route exact path="/privacy-policy" component={PrivacyPolicyComponenet} />
                <Route exact path="/blogs/buying-vs-renting" component={Blog1Component} />
                <Route exact path="/blogs/beginners-guide" component={Blog2Component} />
                <Route exact path="/blogs/tips-for-renting-furniture" component={Blog3Component} />
                <Route exact path="/blogs/furniture-rentals" component={Blog4Component} />
                <Route exact path="/blogs/aesthetic-ideas" component={Blog5Component} />
                <Route exact path="/blogs/upgrade-lifestyle" component={Blog6Component} />
                <Route exact path="/blogs/seasonal-selfcare" component={Blog7Component} />
                <Route exact path="/blogs/types-of-wood" component={Blog8Component} />
                <Route exact path="/blogs/best-furniture-on-rent-in-gurgaon" component={Blog9Component} />
                <Route exact path="/blogs/sofa-on-rent-in-gurgaon" component={Blog10Component} />
                <Route exact path="/blogs/rented-appliances-in-gurgaon" component={Blog11Component} />
                <Route exact path="/blogs/get-a-bed-on-rent-in-gurgaon" component={Blog12Component} />
                <Route exact path="/blogs/get-a-fridge-on-rent-in-gurgaon" component={Blog13Component} />
                <Route exact path="/blogs/study-table-on-rent-in-gurgaon" component={Blog14Component} />
                <Route exact path="/blogs/rent-a-chair-in-gurgaon" component={Blog15Component} />
                <Route exact path="/terms-conditions" component={TermsAndConditionsComponent} />
                <Route exact path="/password/reset/:username/:token" component={ResetPassword} />
                <Route exact path="/search/:query" component={SearchComponent} />
                <Route exact path="/login" component={LoginPageComponent} />
                <Route exact path="/" component={HomeComponent} />
                <Route exact path="/:city" component={HomeComponent} />
                <Route exact path="/thankyou" component={HomeComponent} />
                <Route exact path="/:city/:category" component={CategoryComponent} />
                <Route exact path="/:city/:category/:subCategory" component={SubCategoryComponent} />
                <Route exact path="/:city/:category/:subCategory/:productSlug" component={ProductComponent} />
            </Switch>
        </BrowserRouter>
    </Provider>
);


ReactDOM.render(routing, document.getElementById('root'));
serviceWorker.unregister();
