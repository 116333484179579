import React, { Component } from 'react';

import CartItemsWrapper from './CartItemsWrapper';
import Coupon from './Coupon';
import OrderInfo from './OrderInfo';
import Billing from './Billing';
class OrderDetails extends Component {


    render() {
        return (
           <React.Fragment>
               <CartItemsWrapper 
                    manageQuantity={this.props.manageQuantity}
                    deleteProductFromCart={this.props.deleteProductFromCart} 
                    cart={this.props.cart} />
               {  
                  (this.props.isMedical != true) && (   
                    <Coupon calculateBilling = {this.props.calculateBilling} />
                  )
                }                
               <Billing cart={this.props.cart} isMedical={this.props.isMedical} isPremium ={this.props.isPremium}/>
               <OrderInfo isMedical={this.props.isMedical} />
           </React.Fragment>
        );
    }
}

export default OrderDetails;