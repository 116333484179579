import React, { Component } from 'react';
import H2Title from './../../../Generic/Element/Heading';
import * as keyclockService from './../../../../Service/keyclockService';
import * as service from './../../../../Service/service';
import { connect } from 'react-redux';
import validator from 'validator';
import H1Title from '../../../Generic/Element/LoginHeading';


class LoginComponent extends Component {
    state = {
        isLoginError: {
            verified: false,
            value: '',
        },
        isOtpError: {
            verified: false,
            value: '',
        },
        isDisabled: false,
        isOTPDisabled: false,
        OtpMessage: '',
        otpSent: false, // For showing the OTP input
        isVerified: false,
        countdown: 0, // Countdown for timer
        showError: {
            error: '',
            verified: false,
        }
    };

    validateMobile = () => {
        const { username } = this.props.state.userData;

        if (validator.isEmail(username)) {
            // If it's an email, no validation error
            this.setState({ showError: { error: '', verified: false } });
            return;
        }

        const isValidMobile = /^\d{10}$/.test(username);

        this.setState({
            showError: {
                error: isValidMobile ? '' : 'Invalid Mobile number. Must be exactly 10 digits.',
                verified: !isValidMobile,
            },
        });
    };


    handleChange = (e, element) => {
        let tmpState = JSON.parse(JSON.stringify(this.props.state));
        tmpState.userData[element] = e.target.value;
        this.props.updateState(tmpState);
    };
 
    handleSendOtp = () => {
        const { username } = this.props.state.userData;

        // Check if the username is a phone number and has more than 10 digits
        if (/^\d+$/.test(username) && username.length != 10) {
            this.setState({
                isOtpError: {
                    verified: true,
                    value: 'Mobile number must be of 10 digits.',
                },
            });
            return;
        }

        
        this.setState({
            isOtpError: {
                verified: false,
                value: '',
            },
            isOTPDisabled: true

        })
        if (this.props.state.userData.username.length >= 4) {
            service.sendOtp(this.props.state.userData.username).then((response) => {
                console.log(response);
                this.props.state.userData.uuid = response.data.uuid;
                this.state.OtpMessage = response.data.message;
                this.state.otpSent = true;

                this.setState({ showOtpInput: true, countdown: 60, isOtpError: { verified: false } });

                const timer = setInterval(() => {
                    this.setState((prevState) => {
                        if (prevState.countdown <= 1) {
                            clearInterval(timer);
                            return { isOTPDisabled: false, countdown: 0 };
                        }
                        return { countdown: prevState.countdown - 1 };
                    });
                }, 1000);
            }, (err) => {
                this.setState({
                    ...this.state,
                    isOtpError: {
                        verified: true,
                        value: err.response?.data?.message || 'An unexpected error occurred. Please try again.',
                        
                    }
                });
                localStorage.setItem('mobileNumber', this.props.state.userData.username);
            }).catch((error) => {
                console.log(error);
                this.setState({
                    isOtpError: {
                        verified: true,
                        value: error.response?.data?.message || '',
                    },
                });
            });
        }
    };

    loginUser = () => {
        this.setState({
            ...this.state,
            isLoginError: {
                verified: false,
                value: '',

            },
            showError: {
                error: '',
                verified: false,
            },
            isDisabled: true,
        });


        service.validateOtp(this.props.state.userData.otp, this.props.state.userData.uuid).then(
            (response) => {
                console.log("Validate res", response);

                var keycloak = {
                    access_token: response.data.token,
                    token_type: "bearer",
                    "not-before-policy": 0,
                    session_state: "dfc35da7-b677-45f8-a0ef-c48ba9c67dea",
                    scope: "email profile",
                };

                localStorage.setItem("keycloak", JSON.stringify(keycloak));
                this.props.loginUser(keycloak);

                const event = new Event("login");
                window.dispatchEvent(event);

                this.getLoginInfo();

                if (window.location.search.indexOf("url") !== -1) {
                    window.location.href = window.location.search.split("url=")[1];
                }
            },
            (err) => {
                console.error("Error during login:", err);
                this.setState({
                    ...this.state,
                    isLoginError: {
                        verified: true,
                        value: err.response?.data?.message || 'An unexpected error occurred. Please try again.',

                    },
                    isDisabled: false,
                });
            }
        ).catch((error) => {
            console.error(error);
            this.setState({
                isLoginError: {
                    verified: true,
                    value: error.response?.data?.message || 'An unexpected error occurred. Please try again.',
                },
                showError: {
                    error: 'Invalid Mobile number. Must be exactly 10 digits.',
                    verified: true,
                }
            });
        });
    };

    getLoginInfo = () => {
        service.getUserInfo().then(
            (response) => {
                this.props.updateUserInfo(response.data);

                setTimeout(() => {
                    this.props.hideLoginWindow();
                }, 200);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    render() {
        return (
            <React.Fragment>

                <H1Title value="Login to continue" />

                {this.state.showError.verified && (
                    <div className="mt-3">
                        <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                        >
                            <strong>Check Your Mobile number or e-mail</strong>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}
                {this.state.isLoginError.verified && (
                    <div className="mt-3">
                        <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                        >
                            <strong>{this.state.isLoginError.value}</strong>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}
                {this.state.isOtpError.verified && (
                    <div className="mt-3">
                        <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                        >
                            <strong>{this.state.isOtpError.value}</strong>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}
                {this.state.otpSent && (
                    <div className="mt-3">
                        <div
                            className="alert alert-success alert-dismissible fade show"
                            role="alert"
                        >
                            <strong>{this.state.OtpMessage}</strong>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}

                <div className="form-group mt-3">
                    <input
                        value={this.props.state.userData.username}
                        onChange={(e) => this.handleChange(e, "username")}
                        onBlur={this.validateMobile}
                        className="form-control"
                        placeholder="Enter email or Phone number"
                        maxLength={validator.isMobilePhone(this.props.state.userData.username) ? 10 : undefined}
                    />

                    <small id="emailHelp" className="form-text text-muted">
                        We'll never share your details with anyone else.
                    </small>
                </div>

                <div className="form-group">
                    <button
                        onClick={this.handleSendOtp}
                        type="button"
                        className="btn btn-primary btn-block"
                        disabled={this.state.isOTPDisabled}
                    >
                        {this.state.countdown > 0
                            ? `Login in (${this.state.countdown}s)`
                            : "Login"}
                    </button>
                    {this.state.showOtpInput &&
                        this.props.state.userData.username.length >= 4 && (
                            <input
                                value={this.props.state.userData.otp}
                                onChange={(e) => this.handleChange(e, "otp")}
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter OTP"
                                maxLength={8}
                            />
                        )}
                </div>
                {this.state.showOtpInput &&
                    this.props.state.userData.username.length >= 4 && (
                        <div>
                            <div className="form-group form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="exampleCheck1"
                                />
                                <label
                                    className="form-check-label text-black-50"
                                    htmlFor="exampleCheck1"
                                >
                                    Remember Me
                                </label>
                            </div>

                            <button
                                onClick={() => { this.loginUser(); }}

                                type="button"
                                className={
                                    this.props.isGeneralLogin
                                        ? "btn btn-primary btn-block mb-2"
                                        : "btn btn-primary btn-sm btn-block mb-2"
                                }
                                disabled={this.state.isDisabled}
                            >
                                Submit
                            </button>
                        </div>
                    )}
                {!this.props.isGeneralLogin && (
                    <button
                        type="button"
                        onClick={(e) => this.props.changeScreen("register")}
                        className="btn btn-success btn-block"
                        style={{marginTop:'20%'}}
                    >
                        Register
                    </button>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        userState: currentState.userState,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        loginUser: (data) => {
            dispatch({ type: "user.auth.login", data: data });
        },
        hideLoginWindow: (data) => {
            dispatch({ type: "login.window.hide", data: data });
        },
        updateUserInfo: (data) => {
            dispatch({ type: "login.update.userInfo", data: data });
        },
    };
};

export default connect(mapStateToProps, mapActionsToProps)(LoginComponent);
