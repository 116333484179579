import React, { Component } from 'react';

class Blogs extends Component {
      
    render() {
        return (
            <React.Fragment>
            <div className="mt-3 mb-3" style={{ background: `url('https://cdn.pixabay.com/photo/2016/11/18/17/20/living-room-1835923_960_720.jpg')`, width: '100%', backgroundPosition:'10px' }}>
                <div id="carouselBlog" className="carousel slide testImage" data-ride="carousel" style={{ minHeight: '200px', padding: '100px 0px 120px 0px' }}>
                    <ol className="carousel-indicators">
                        <li data-target="#carouselBlog" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselBlog" data-slide-to="1"></li>
                        <li data-target="#carouselBlog" data-slide-to="2"></li>
                        <li data-target="#carouselBlog" data-slide-to="3"></li>
                        <li data-target="#carouselBlog" data-slide-to="4"></li>
                        <li data-target="#carouselBlog" data-slide-to="5"></li>
                        <li data-target="#carouselBlog" data-slide-to="6"></li>
                        <li data-target="#carouselBlog" data-slide-to="7"></li>
                        <li data-target="#carouselBlog" data-slide-to="8"></li>
                        <li data-target="#carouselBlog" data-slide-to="9"></li>
                        <li data-target="#carouselBlog" data-slide-to="10"></li>
                        <li data-target="#carouselBlog" data-slide-to="11"></li>
                        <li data-target="#carouselBlog" data-slide-to="12"></li>
                        <li data-target="#carouselBlog" data-slide-to="13"></li>
                        <li data-target="#carouselBlog" data-slide-to="14"></li>
                    </ol>
                    <div className="carousel-inner">
                        
                    <div className="carousel-item active">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">High-Quality Rented Furniture at Voorent</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Voorent is a well-known brand when it comes to the furniture rental industry, successfully catering to the needs of individuals and families in Gurgaon. Doesn’t matter if you are a working professional, a student, or a family moving into a new house, Voorent provides an effortless solution to decorating your space without the burden of buying new & expensive furniture. Familiar with the fast-paced lifestyle of Gurgaon, Voorent makes sure that you can rent high-quality furniture at affordable prices with flexible rental plans according to your requirements</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/best-furniture-on-rent-in-gurgaon" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Stylish & Comfortable Rented Sofas at Voorent</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Imagine this: You’ve just moved to Gurgaon—a vibrant city of opportunities—and you want your home to be aesthetic to match your lifestyle. But buying expensive furniture? Not a great idea. That’s where Voorent comes into the picture. Being one of the topmost furniture rental Businesses in Gurgaon, Voorent offers you the ideal balance of convenience, affordability, and style.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/sofa-on-rent-in-gurgaon" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Rent Appliances in Gurgaon: Smart Solutions for Students</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Being a student in Gurgaon is exciting—new experiences, new friends, and the freedom to decorate your own space. But let's be real—setting up your space with furniture & appliances can be expensive and stressful when you are on a limited budget. That’s why Voorent is here to help!</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/rented-appliances-in-gurgaon" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Rent a Bed Because Your Back Deserves Better</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Shifting houses in Gurgaon is no joke—packing, unpacking, and somewhere along the way, your bed pays the price. If you’re tired of broken bed frames and sleepless nights, Voorent is here to save your spine.
                                    Voorent offers sturdy, high-quality beds on rent with lightning-fast delivery and zero fuss. Whether your old bed couldn’t survive the latest move or you’re just done with sleeping on the floor, Voorent’s got your back.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/get-a-bed-on-rent-in-gurgaon" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Keep It Cool: Get a Fridge on Rent in Gurgaon with Voorent</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>So, you got that internship in Gurgaon—congrats! But here’s the thing: PG life is not as fancy when your drinks are lukewarm, and your midnight leftovers don’t make it through the night. What if you could have a fridge without having the hassle of buying one?</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/get-a-fridge-on-rent-in-gurgaon" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Get a Study Table on Rent in Gurgaon with Voorent</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Still studying for exams in bed? We get it—balancing books, laptops, and snacks while resisting the urge to nap is not an easy task. But imagine if you had a decent study setup that didn’t cost a fortune.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/study-table-on-rent-in-gurgaon" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">One Chair Down? No Stress—Rent a Chair in Gurgaon with Voorent!</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Picture this: You and your favourite chair, an inseparable duo—until one day, the unexpected happens. Whether it’s a broken leg or a chair that finally gave up after years of usage, losing a chair can make the whole setup off balance. But why spend your hard-earned on a new one when you can rent a chair in Gurgaon from Voorent?</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/rent-a-chair-in-gurgaon" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Renting vs Buying: Which is the Better Option for You?</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>It might be challenging to decide whether to rent or buy furniture and appliances. On the one hand, purchasing implies ownership of the goods and the power to alter them. Renting, on the other hand, offers flexibility and the chance to swap out stuff more frequently. In this article, we'll examine both alternatives' benefits and drawbacks to assist you in choosing which is best for you.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/buying-vs-renting" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">A Beginner's Guide to Renting Furniture and Appliances</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Every time you move, do you have to carry heavy furniture up and down stairs? Do you want to make a budget-friendly décor change? Renting appliances and furniture could be the answer you've been looking for. We'll go over everything you need to know to get started in this beginner's guide.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/beginners-guide" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Tips for Renting Furniture</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>When it comes to furnishing your living space for a short-term or long-term stay, renting furniture can be a convenient and cost-effective solution. With the option to rent a wide range of furniture and appliances, you can easily transform your space to suit your needs without the commitment of buying. In this article, we will provide you with essential tips and insights on renting furniture for your stay, along with the convenience of renting from Voorent, a trusted furniture rental company.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/tips-for-renting-furniture" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Furniture Rentals: A Millennial Way of home décor</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>In this fast-moving world I usually ask my self that do I really have the time to explore furniture and decorate my home the way I want? Well to be very honest I don’t really think so. We are living in an era where we have been on the move all the time, we have to look after a lot of things these days like figuring out office work, caring about our loved ones, looking after them at the same time, and when all that is done, I guess we hardly have time for ourselves.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/furniture-rentals" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">5 ideas to make your room look more aesthetic</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>In today's stressful world, your room is the only place where you can relax, recharge and express, yourself, creating a space that can resonate with your personality, where you can feel less stressed with an aesthetic look.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/aesthetic-ideas" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Tips to Upgrade Your Lifestyle</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Life is a perpetual journey of learning and self-improvement. Whether it's to boost your fitness and health, productivity, or overall happiness. Here's a blog which can help you out in upgrading your lifestyle.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/upgrade-lifestyle" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Seasonal Self Care</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>Life is a cycle of changes, like the changing seasons. Each season has its own unique elements; there is time to adapt, adapt and find comfort in the changing nature of the situation. Just as the world around us changes, so does our landscape, requiring us to accept these changes through mindful self-care.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/seasonal-selfcare" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <span className="d-block mt-3 font-weight-bold">Types Of Wood For Furniture</span>
                                    <br></br>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>When it comes to the bedroom, beds play a crucial role in making the room look more aesthetic which makes the selection of wood more important, a wood that is a perfect combination of durability and style. We bring you an informative blog on “Types of wood used for making beds” which can help you out in the selection of the wood which can enhance the vibe of your room.</span>
                                    <br/>
                                </div>
                                <div className="col-8 m-auto">
                                    <span><a href="https://voorent.com/blogs/types-of-wood" type="button" className="btn btn-sm btn-outline-info mt-2 font-weight-bold" target="_blank"> Read More... </a></span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <a className="carousel-control-prev" href="#carouselBlog" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselBlog" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default Blogs;