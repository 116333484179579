import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import FooterComponent from './../../Generic/Footer/FooterComponent';
import HeaderComponent from './../../Generic/Header/HeaderComponent';
import ImageCrousel from './ProductInfo/ImageCrousel';
import ProductInfoComponent from './ProductInfo/ProductInfoComponent';
import * as endpoint from './../../../Service/endpoints';
import * as service from './../../../Service/service';
import H2Title from './../../Generic/Element/Heading';
import SingleProduct from './../../Generic/Element/SingleProduct';
import BreadCrumbs from './../../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';
import ProductSepcifications from '././ProductInfo/ProductSepcifications';
import ProductFAQComponent from '././ProductInfo/ProductFAQComponent';
import ResponseForm from "../Homepage/GoogleForm/ResponseForm";
import ServiceAreaSelect from "../Homepage/ServiceArea/ServiceAreaSelect";

class ProductComponent extends Component {

    state = {
        product: {
            featureList: [],
            gallery: {
                images: []
            }
        },
        productSlug: '',
        inventory: {
            rentOption: []
        },
        durationSelected: 12,
        quantity: 1,
        showSpinner: false,
        showOptions: false,
        searched : '',
        recommendItem: [],
        isLoaded: false,
        openResponseForm:false,
        slugs:[],
    }

    constructor(props) {
        super(props);
        this.state = ({
            ...this.state,
            productSlug: props.match.params.productSlug
        });
    }

    componentDidMount() {
        this.getProductData();
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: endpoint.getRoute('') }, { name: 'Product', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
       
    }

    manageQuantity = (op) => {
        if (op == '+') {
            this.setState({
                ...this.state,
                quantity: parseInt(this.state.quantity) + 1
            });
        } else if (op == '-') {
            if (this.state.quantity != 1) {
                this.setState({
                    ...this.state,
                    quantity: parseInt(this.state.quantity) - 1
                });
            }
        }
    }

    

    getProductData = () => {

        service.getSingleProduct(this.state.productSlug).then((response) => {
            this.setState({
                ...this.state,
                product: response.data,
                isLoaded: true
            })
            if(this.state.product.isMedical){
                this.setState({durationSelected:1});
            }
            this.checkProductInventory(response.data.id);
            this.getRecommendedItem(response.data.id);
        }, (error) => {
            console.log(error);
        });

    }

    checkProductInventory = (productId) => {
        service.checkInventory(productId).then((response) => {
            this.setState({
                ...this.state,
                inventory: response.data
            });
        }, (error) => {
            console.log(error);
        });
    }

    selectDuration = (e) => {
        this.setState({
            ...this.state,
            durationSelected: (parseInt(e.target.value))
        })
    }

    showPrice = (type) => {
        if (type == 'single') {
            let price = '0';
            this.state.inventory.rentOption.map((rent) => {
                if (rent.period == this.state.durationSelected) {
                    price = rent.price;
                }
            })
            return price;
        } else if (type == 'final') {
            let price = '0';
            this.state.inventory.rentOption.map((rent) => {
                if (rent.period == this.state.durationSelected) {
                    price = rent.price;
                }
            })
            return price * this.state.quantity;
        }
    }

    addItemToCart = () => {
        if (this.props.userState.loggedIn) {
            this.setState({
                ...this.state,
                showSpinner: true
            });
    
            service.checkCart().then((response) => {
                const cartItems = response.data.cartProductList || [];
    
                if (cartItems.length > 0) {
                    if (cartItems[0].isPremium && this.state.product.category.slug !== 'premium') {
                        alert('Please add only premium products or remove premium products from the cart to continue.');
                        this.setState({ ...this.state, showSpinner: false });
                        return;
                    } else if (!cartItems[0].isPremium && this.state.product.category.slug === 'premium') {
                        alert('Please add only non-premium products or remove non-premium products from the cart to continue.');
                        this.setState({ ...this.state, showSpinner: false });
                        return;
                    } else {
                        this.updateCart();
                    }
                } else {
                    
                    this.updateCart();
                }
            }).catch((error) => {
                console.error("Error checking cart:", error);
                this.setState({ ...this.state, showSpinner: false });
            });
    
        } else {
            this.props.showLoginWindow();
        }
    };
    

    updateCart=()=>{
        
        let cart = {
            productId: this.state.product.id,
            productSlug: this.state.product.slug,
            tenure: (this.state.product.isMedical==true?1:this.state.durationSelected),
            quantity: this.state.quantity,
            monthlyRent: this.showPrice('final'),
            securityDeposit: parseFloat(this.showPrice('final')) * 2 ,
            isMedical: (this.state.product.isMedical==true?true:false),
            isPremium:(this.state.product.category.slug==='premium'?true:false),
        };

        console.log('is: ',this.state.product.category.slug);
        service.addToCart(cart).then((response) => {

            this.setState({
                ...this.state,
                showSpinner: false,
                showOptions: true
            });

        }, (error) => {
            console.log(error);
        });
    }

    getRecommendedItem = (pid)=>{

        service.recommendedItems(pid).then((response)=>{
            this.setState({
                ...this.state,
                recommendItem: response.data
            })
        },(error) => {
            console.log(error);
        });
    }

    responseForm() {
        this.setState({...this.state,
          openResponseForm : true});
      }

    render() {
        setTimeout(()=>this.responseForm(), 5000)
        return (
            <React.Fragment>
                <Helmet>
                    <link rel="canonical" href={`https://voorent.com/undefined/${this.state.category}/${this.state.subCategory}/${this.state.productSlug}`} />
                </Helmet>

                {
                (window.location.href.indexOf("medium") > -1) &&(
                    
                    
                    <div>

                    {
                        this.state.openResponseForm && (
                        <ResponseForm/>
                        )
                    }
                    {
                        !this.props.surveyModal.show && (
                        <ServiceAreaSelect/>)
                    }
                </div>)
                }
                <HeaderComponent />

                <BreadCrumbs navigation={[{ name: 'Home', link: endpoint.getRoute('') }, { name: 'Products', link: '#' }]}
                    showSidebar={false} />
                <div>
                {
                    !this.state.isLoaded && <div className="col-12 m-auto">
                        <div className="row">
                            <div className="col-12 text-center mt-5">
                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <span className="pl-3 text-black-50 d-block" >Checking Inventory ..</span>
                            </div>
                        </div>
                    </div>
                }

{
                this.state.isLoaded && this.state.product.length === 0 && (
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 text-center mt-5">
                                <img height="80px" src="./../svg/noresult.svg" />
                                <h5 className="mt-3 mb-5 text-black-50">Sorry ! No Items available with following search option.</h5>
                            </div>
                        </div>
                    </div>)
                }

                {
                    this.state.isLoaded && (
                    <main role="main" className="container mt-5">
                              
                        
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <ImageCrousel featuredImage = {this.state.product.featuredImage} images={this.state.product.gallery.images} status={this.state.product.status}/>
                            </div>
                            <div className="col-12 col-md-6">

                                <ProductInfoComponent
                                    showSpinner={this.state.showSpinner}
                                    showOptions={this.state.showOptions}
                                    addItemToCart={this.addItemToCart}
                                    quantity={this.state.quantity}
                                    manageQuantity={this.manageQuantity}
                                    durationSelected={this.state.durationSelected}
                                    showPrice={this.showPrice}
                                    selectDuration={this.selectDuration}
                                    inventory={this.state.inventory}
                                    product={this.state.product} 
                                    
                                    />
                            </div>
                        </div>
                        <br/>


                        <div>
                            <ul className="nav nav-pills  " role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="product-detail-link" data-toggle="tab" href="#product-detail-tab" role="tab" aria-controls="product-detail-tab" aria-selected="true">Product Details</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="product-faq-link" data-toggle="tab" href="#product-faq-tab" role="tab" aria-controls="product-faq-tab" aria-selected="false">FAQ</a>
                                </li>
                                
                            </ul>
                            
                            <div className="tab-content">
                                
                                <div className="tab-pane fade active show" id="product-detail-tab" role="tabpanel" aria-labelledby="product-detail-link">
                                    
                                    <div className="product-detail-content">
                                        <div className="col-12 mt-3 pl-0" style={{ border: '1px solid #DDD' }}>
                                            <div className="row p-3">
                                                {
                                                    this.state.product.featureList.length != 0 && this.state.product.featureList.map((spec, sIndex) => (
                                                        <ProductSepcifications key={sIndex} specs={spec} />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="product-faq-tab" role="tabpanel" aria-labelledby="product-faq-link">
                                    <div className="product-faq-content">
                                        <ProductFAQComponent />                   
                                    </div>
                                </div>
                            
                             </div>
                        </div>

                        <div className="row mt-4">
                        <div className="col-12">
                            <H2Title value={'You may also like this'} />
                            <div className="text-secondary ml-2 mb-3">Products recommendation based on your search activity.</div>
                            
                            <div className="row">          
                                {   
                                    this.state.recommendItem.length != 0 && this.state.recommendItem.map((prod, index) => (
                                        index < 4 && <SingleProduct length={this.state.recommendItem.length} minTenure={prod.isMedical?'1 Month':'6 Months'} key={index} product={prod} styleValue={{ padding: '' }} />
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    </main>)
                    }
                </div>
                <FooterComponent />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        userState: currentState.userState,
        surveyModal: currentState.surveyModal
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        showLoginWindow: () => {
            dispatch({ type: 'login.window.show' });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(ProductComponent);