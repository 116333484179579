import React, { Component } from 'react';
import IosAddCircleOutline from 'react-ionicons/lib/IosAddCircleOutline';
import IosRemoveCircleOutline from 'react-ionicons/lib/IosRemoveCircleOutline';

import * as endpoint from './../../../../Service/endpoints';

class RentSelector extends Component {

    styles = {
        element: {
            minHeight: '270px',
            background: '#f8f9fa',
            position: 'relative',
            paddingBottom: '40px'
        }
    }
    state = {
        rentPeriod: [6,9,12],
    }

    goToCart = () => {
        window.location.href = endpoint.getRoot("cart");
    }
    

    getOptions = () => {
        if (this.props.showSpinner) {
            return (
                <React.Fragment>
                    <div className="col-12">
                        <button type="button" onClick={this.props.addItemToCart} className="btn btn-primary btn-block">
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="ml-2">Adding</span>
                        </button>
                    </div>
                </React.Fragment>
            );
        } else if (this.props.showOptions) {
            return (
                <React.Fragment>
                     <div className="col-6">
                        <button onClick={this.goToCart} type="button" className="btn btn-success btn-block">
                            <span className="ml-2">View Cart</span>
                        </button>
                    </div>
                    <div className="col-6">
                        <button type="button" onClick={this.props.addItemToCart} className="btn btn-primary btn-block">
                            <span className="ml-2">Update Cart</span>
                        </button>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="col-12">
                        <button type="button" disabled={this.props.status!=='Out of Stock'?false:true} onClick={this.props.addItemToCart} className="btn btn-primary btn-block">
                            <span className="ml-auto">Add To Cart</span>
                        </button>
                    </div>
                    {
                        this.props.isVirtual===true &&
                    (<div className="col-12">
                        <a type="button" href={this.props.tagName.includes("VRBedroom")?window.location.origin + '/vrBedroom':window.location.origin + '/vrKitchen'} className="btn btn-success btn-block mt-2">
                            <span className="ml-auto">View in Virtual</span>
                        </a>
                    </div>)
                    }
                </React.Fragment>
            );
        }
    }


    getDistance = (index) => {
        if (index == 0) {
            return '5%'
        } else if (index == 1) {
            return '50%'
        } else if (index == 2) {
            return '93%'
        }
        else if (index == 3) {
            return '95%'
        }
    }

    render() {
        return (
            <div className="col-12 mt-3" style={this.styles.element}>
                { 
                (this.props.isMedical != true)&&(this.props.isPremium != true)&&(
                        <div className="row pt-3">

                            <div className="col-8 m-auto mb-3 text-center">
                                <h5 className="font-weight-bold">Choose a plan</h5>
                            </div>

                            <div className="col-8 m-auto">
                                <input onChange={this.props.selectDuration} defaultValue={this.props.durationSelected} type="range" className="custom-range" min="6" max="12" step="3" id="customRange3" disabled={this.props.isPremium===true?true:false}/>
                                {
                                    
                                    this.state.rentPeriod.map((rent, rIndex) => (
                                        <div key={rIndex} style={{ position: 'absolute', left: this.getDistance(rIndex) }}>
                                            <span style={{ display: 'block' }}> | </span>
                                            <span style={{ position: 'relative', left: '-10px', whiteSpace: 'nowrap' }}> {rent} Mo </span>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                )
                }
                <div className={this.props.isMedical?"row pt-5":"row"}>
                    <div className="col-12 text-center" style={this.props.isMedical?{}:{ marginTop: '70px' }}>
                        <div className="row">
                            {
                                (this.props.isMedical != true)&& (this.props.isPremium != true) && (
                                    <div className="col-12">
                                        <span className="font-weight-bold">Monthly Rent :</span> &nbsp;
                                    <h6 style={{ display: 'inline-block' }} className="mb-2"> Rs {parseFloat(this.props.showPrice('single')/1.18).toFixed(0)} / Month</h6>
                                    </div>
                                        )
                            }
                            {
                                (this.props.isMedical != true)&& (this.props.isPremium === true) && (
                                    <div className="col-12">
                                        <span className="font-weight-bold">Monthly Rent :</span> &nbsp;
                                    <h6 style={{ display: 'inline-block' }} className="mb-2"> Rs {parseFloat(this.props.showPrice('single')/1.18).toFixed(0)} / Month</h6>
                                    </div>
                                        )
                            }

{
                            (this.props.isMedical == true) && (
                                <div className="col-12">
                                    <ul>
                                        <li>You can add the product to cart and checkout in next stage.</li>
                                        <li>We will connect with you shortly with pricing and delivery information.</li>
                                    </ul>
                                </div>
                        )}

                            <div className={this.props.isMedical==true ? "col-12 mb-4":"col-12 mt-1"}>
                                <span className="font-weight-bold">Quantity : </span> &nbsp;
                            <IosRemoveCircleOutline onClick={(e) => this.props.manageQuantity('-')} /> &nbsp; {this.props.quantity} &nbsp; <IosAddCircleOutline onClick={(e) => this.props.manageQuantity('+')} />
                            </div>
                            {
                                (this.props.isMedical != true && this.props.isPremium != true) && (

                                <div className="col-12 mt-2">
                                    <span className="font-weight-bold">Monthly Total  : </span> &nbsp;
                                <h6 style={{ display: 'inline-block' }} className="mb-4"> Rs {parseFloat(this.props.showPrice('final')/1.18).toFixed(0)} / Month</h6>
                                </div>
                            )}
                            {
                                (this.props.isMedical != true && this.props.isPremium === true) && (

                                <div className="col-12 mt-2">
                                    <span className="font-weight-bold">Monthly Total  : </span> &nbsp;
                                <h6 style={{ display: 'inline-block' }} className="mb-2"> Rs {parseFloat(this.props.showPrice('final')/1.18).toFixed(0)} / Month</h6>
                                </div>
                            )}
                            {this.props.isPremium&&
                            <div className='col-12 mt-2'>
                                <span className='font-weight-bold'>Tenure  : 12 Months</span>
                            </div>}
                            {
                                (this.props.isMedical != true && this.props.isPremium === true) && (

                                <div className="col-12 mt-2">
                                    <span className="font-weight-bold">Total  : </span> &nbsp;
                                <h6 style={{ display: 'inline-block' }} className="mb-4"> Rs {parseFloat((this.props.showPrice('final')/1.18)*12).toFixed(0)}</h6>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">

                    {this.getOptions()}
                </div>


            </div>
        );
    }
}

export default RentSelector;