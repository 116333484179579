import React, { Component } from 'react';
import MdArrowRoundBack from 'react-ionicons/lib/MdArrowRoundBack';

class Billing extends Component {
    render() {
        return (
            <div className="row mb-4 ml-1 mr-1">
                    <div className="col-12" style={{background:'#f8f9fa'}}>
                    <div className="row p-2 pt-3 pb-3">
                        <div className="col-12 col-md-4 d-none d-md-block position-relative">
                            <MdArrowRoundBack fontSize="24px" color="#645e55" />
                            <a href="https://voorent.com" className="ml-4 text-black-50">Continue Shopping</a>
                        </div>
                        <div className="col-12 col-md-8 text-right">
                                <div className="row">
                                <div className="col-12 col-md-8 offset-md-4">
                                    <div className="row">
                                        <div className="col-12 text-left pb-1 pt-1 mt-1 mb-1 font-weight-bold" style={{color:'#004980', borderBottom:'2px solid #004980'}}>
                                            <span className="no-white-space">Payment Details</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8 offset-md-4">
                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <span className="no-white-space">Monthly Rent</span>
                                        </div>
                                        <div className="col-6">
                                            <span className="font-weight-bold">Rs {parseFloat(this.props.cart.billing.totalRent/1.18).toFixed(2)  || 0}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                     this.props.isMedical == true && (
                                        <div className="col-12 col-md-8 offset-md-4">
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <span className="no-white-space">GST</span>
                                                </div>
                                                <div className="col-6">
                                                    <span className="font-weight-bold">Rs {parseFloat(this.props.cart.billing.totalRent * 0.18).toFixed(2)}</span>
                                                </div>
                                            </div>
                                            <div className="pt-1 pb-1 mt-1 mb-1 font-weight-bold" style={{ borderTop :'2px solid rgb(0, 73, 128)'}}>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <span className="no-white-space">Total Payable</span>
                                                </div>
                                                <div className="col-6">
                                                    <span className="font-weight-bold">Rs {parseFloat(this.props.cart.billing.totalRent + (this.props.cart.billing.totalRent*0.18)).toFixed(1) || 0}</span>
                                                </div>
                                            </div>
                                            </div>
                                
                                        </div>
                                     )
                                } */}

                                <div className="col-12 col-md-8 offset-md-4">
                                    <div className="row">
                                        <div className="col-6 text-left">
                                            {this.props.isPremium===true?<span className="no-white-space">Advance</span>
                                            :
                                            <span className="no-white-space">Security Deposit</span>
                                            }
                                        </div>
                                        <div className="col-6">
                                            <span className="font-weight-bold">Rs {parseFloat(this.props.cart.billing.totalDeposit/1.18).toFixed(2) || 0}</span>
                                        </div>
                                    </div>
                                    <div className="pt-1 pb-1 mt-1 mb-1 font-weight-bold" style={{ borderTop :'2px solid rgb(0, 73, 128)'}}>
                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <span className="no-white-space">Total Amount</span>
                                        </div>
                                        <div className="col-6">
                                            <span className="font-weight-bold">Rs {parseFloat(this.props.cart.billing.totalRent/1.18 + this.props.cart.billing.totalDeposit/1.18).toFixed(2) || 0}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                {
                                     this.props.isMedical != true && (
                                        <div className="col-12 col-md-8 offset-md-4">
                                            {/* <div className="row">
                                                <div className="col-6 text-left">
                                                    <span className="no-white-space">Security Deposit</span>
                                                </div>
                                                <div className="col-6">
                                                    <span className="font-weight-bold">Rs {parseFloat(this.props.cart.billing.totalDeposit/1.18).toFixed(0) || 0}</span>
                                                </div>
                                            </div> */}

                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <span className="no-white-space">GST (18%)</span>
                                                </div>
                                                <div className="col-6">
                                                    <span className="font-weight-bold">Rs {parseFloat(0.18*((this.props.cart.billing.totalRent + this.props.cart.billing.totalDeposit)/1.18)).toFixed(2)}</span>
                                                </div>
                                            </div>
                                        
                                        <div >
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <span className="no-white-space">Delivery</span>
                                                </div>
                                                <div className="col-6">
                                                    <span className="font-weight-bold"> {this.props.cart.billing.deliveryFee == 0 ? 'Free' : 'Rs '+ this.props.cart.billing.deliveryFee || 0}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div >
                                            
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <span className="no-white-space">Coupon Discount (Monthly)</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="font-weight-bold">- Rs {this.props.cart.billing.couponDiscount || 0}</span>
                                                    </div>
                                                </div>
                                            
                                            
                                        </div>

                                        <div className="pt-1 pb-1 mt-1 mb-1 font-weight-bold" style={{ borderTop :'2px solid rgb(0, 73, 128)'}}>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <span className="no-white-space">Total Payable</span>
                                                </div>
                                                <div className="col-6">
                                                    <span className="font-weight-bold">Rs {parseFloat(this.props.cart.billing.payable).toFixed(2) || 0}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                                
                                


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Billing;